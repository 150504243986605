import * as React from 'react';
import * as styles from './Container.module.scss';
import clsx from 'clsx';

type ContainerProps<T extends React.ElementType> = {
  as?: T;
  id?: string;
  children: React.ReactNode;
  className?: string;
} & React.ComponentPropsWithoutRef<T>;

export const Container = <T extends React.ElementType = 'div'>({
  as,
  children,
  className,
  ...props
}: ContainerProps<T>) => {
  const Tag: React.ElementType = as || 'div';

  return (
    <Tag className={clsx(styles.container, className)} {...props}>
      {children}
    </Tag>
  );
};
