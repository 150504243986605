import React from 'react';
import './src/styles/reset.scss';
import './src/styles/global.scss';
import { ErrorPage } from './src/app/errorPage/ErrorPage';
import '@fontsource/poppins';
import * as Sentry from '@sentry/gatsby';

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  // Don't scroll to top when changing filters in params on work page
  if (location.hash === '') {
    window.scrollTo(0, 0);
  }

  return false;
};

if (process.env.GATSBY_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.GATSBY_SENTRY_DSN,
    environment: process.env.GATSBY_ENV,
  });
}

export const wrapPageElement = ({ element }) => (
  <Sentry.ErrorBoundary fallback={<ErrorPage />}>{element}</Sentry.ErrorBoundary>
);
