import { FooterQuery } from '@/generated/graphql';
import { Button } from '@/ui/components/button/Button';
import { Container } from '@/ui/components/container/Container';
import clsx from 'clsx';
import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import { FooterNewsletterForm } from '../footerNewsletterForm/FooterNewsletterForm';
import { NewWindowIcon } from '@/ui/components/icons/NewWindowIcon';
import * as styles from './Footer.module.scss';
import { routes } from '@/routes/routes';

export const Footer = ({ isContentLimited }: { isContentLimited?: boolean }) => {
  const currentYear = new Date().getFullYear();
  const strapiFooterData = useStaticQuery<FooterQuery>(graphql`
    query Footer {
      strapiFooter {
        footerSections {
          id
          title
          footerLinks {
            id
            title
            url
            shouldOpenInNewTab
          }
        }
      }
    }
  `);

  const handleContactServicesClick = () => {
    window.location.href = 'mailto:info@cochraninc.com';
  };

  return (
    <div className={styles.footer__outer}>
      <Container>
        <footer className={styles.footer}>
          <div className={styles.contactSection}>
            <div className={styles.footer__section} style={{ display: 'flex', flexDirection: 'column' }}>
              <p className={styles.contactSection__title}>Subscribe</p>
              <p className={styles.contactSection__desc}>
                The <b>Cochran Wire</b> keeps you plugged into the latest company news, events, and announcements.
              </p>
              <FooterNewsletterForm />
            </div>
            <div className={clsx(styles.footer__section, styles.contactSection__service)}>
              <p className={styles.contactSection__serviceTag}>24/7 service</p>
              <p className={styles.contactSection__serviceDesc}>Get help around the clock from our services team.</p>
              <Button
                variant="white"
                className={styles.contactSection__serviceBtn}
                onClick={handleContactServicesClick}
              >
                Contact services
              </Button>
            </div>
          </div>
          <div className={styles.footer__links}>
            {strapiFooterData.strapiFooter?.footerSections?.map((node) => (
              <ul
                className={clsx(
                  styles.footer__list,
                  styles.footer__section,
                  node!.title === 'Locations' && styles.footer__list___locations,
                )}
                key={node!.id}
              >
                <li>{node!.title}</li>
                {node!.footerLinks?.map((footerLinkNode) => (
                  <li key={footerLinkNode!.id}>
                    <a
                      href={footerLinkNode!.url!}
                      target={footerLinkNode!.shouldOpenInNewTab ? '_blank' : ''}
                      rel="noreferrer"
                    >
                      {`${footerLinkNode!.title}`}{' '}
                      {footerLinkNode?.shouldOpenInNewTab ? (
                        <NewWindowIcon className={styles.footer__linkIcon} />
                      ) : null}
                    </a>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </footer>
        <div className={styles.footer__copyrightInfo}>
          {isContentLimited ? null : (
            <Link to={routes.privacyPolicy} className={styles.footer__privacyPolicyText}>
              Privacy Policy
            </Link>
          )}
          <span>© {currentYear}, Cochran Inc. All Rights Reserved.</span>
        </div>
      </Container>
    </div>
  );
};
