import React, { useState } from 'react';
import * as styles from './FooterNewsletterForm.module.scss';
import { Button } from '@/ui/components/button/Button';

export const FooterNewsletterForm = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [emailAddress, setEmailAddress] = useState('');
  const [submitError, setSubmitError] = useState('');

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!emailAddress) {
      setSubmitError('Please enter an email address');
      return;
    }

    const response = await fetch(process.env.GATSBY_MAILCHIMP_NETLIFY_URL!, {
      method: 'post',
      body: JSON.stringify({
        email_address: emailAddress,
        status: 'pending',
      }),
    });
    const data = await response.text();
    if (response.status !== 200) {
      setSubmitError(data);
    } else {
      setSubmitError(data);
      setIsSubscribed(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.footerNewsletter}>
      <div className={styles.footerNewsletter__wrapper}>
        <input
          id="email"
          name="email"
          placeholder="Email address..."
          disabled={isSubscribed}
          onChange={(e) => setEmailAddress(e.target.value)}
          value={emailAddress}
          className={styles.footerNewsletter__input}
        />
        <Button type="submit" variant="white" disabled={isSubscribed}>
          Subscribe
        </Button>
      </div>
      {submitError ? <p className={styles.footerNewsletter__error}>{submitError}</p> : null}
    </form>
  );
};
