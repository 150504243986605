import clsx from 'clsx';
import * as React from 'react';
import { Container } from '../container/Container';
import * as styles from './Layout.module.scss';
import { Footer } from './components/footer/Footer';
import { Header } from './components/header/Header';
import { CookieBanner } from '../cookieBanner/CookieBanner';

type LayoutProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  withContainer?: boolean;
  topBgClassName?: string;
  headerClassName?: string;
  isContentLimited?: boolean;
};

export const Layout = ({
  children,
  className,
  style,
  withContainer = false,
  topBgClassName = '',
  headerClassName = '',
  isContentLimited = false,
}: LayoutProps) => {
  // Bump visit count on every visit
  React.useEffect(() => {
    const storedVisitCount = parseInt(localStorage.getItem('visitCount') || '0', 10);
    const newVisitCount = storedVisitCount + 1;
    localStorage.setItem('visitCount', newVisitCount.toString());
  }, []);

  return (
    <div className={clsx(styles.layout, className)} style={style}>
      <CookieBanner />
      <Header className={headerClassName} isContentLimited={isContentLimited} />
      {topBgClassName && <div className={clsx(styles.fullWidthBg, topBgClassName)} />}
      <main className={styles.content}>{withContainer ? <Container>{children}</Container> : children}</main>
      <Footer isContentLimited={isContentLimited} />
    </div>
  );
};
