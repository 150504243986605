import { ContactSection } from '@/ui/components/contactSection/ContactSection';
import { Container } from '@/ui/components/container/Container';
import { Layout } from '@/ui/components/layout/Layout';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from './ErrorPage.module.scss';
import clsx from 'clsx';
import { routes } from '@/routes/routes';

export const ErrorPage = () => (
  <Layout>
    <Container>
      <div className={styles.errorPage__hero}>
        <p className={styles.errorPage__title}>Something went wrong</p>
        <p className={styles.errorPage__subtitle}>We are sorry for the inconvenience.</p>
        <div className={styles.errorPage__btnWrapper}>
          <Link to={routes.contact} className={clsx(styles.errorPage__btn, styles.errorPage__btn___contact)}>
            <p className={clsx(styles.errorPage__btnText, styles.errorPage__btnText___contact)}>Contact us</p>
          </Link>
          <Link to={routes.home} className={styles.errorPage__btn}>
            <p className={styles.errorPage__btnText}>Visit homepage</p>
          </Link>
        </div>
      </div>
    </Container>
    <ContactSection withBackgroundImage />
  </Layout>
);
