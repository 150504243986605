import React from 'react';

export const Logo = ({ className }: { className?: string }) => (
  <svg className={className} width="96" height="30" viewBox="0 0 96 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7866_6019)">
      <path
        d="M67.0056 14.0803C64.0864 14.0803 62.3702 15.6804 62.0636 18.1816C61.7594 20.6665 63.0971 22.2248 65.9466 22.2248H68.4477V22.2317H72.1008L73.2527 12.8495C73.2689 12.7171 73.2759 12.587 73.2759 12.4593H73.2968L73.2736 12.4361C73.2736 10.6223 71.8035 9.10353 69.9387 9.09889L69.9317 9.09192H64.9201L64.5067 12.4593H69.6461L69.4463 14.0803H67.0032H67.0056ZM68.8216 19.1941H65.7352L65.9953 17.0715H69.0817L68.8216 19.1941Z"
        fill="currentColor"
      />
      <path
        d="M76.2509 9.09888L74.6392 22.2317H78.2922L79.4905 12.4663H82.5769L81.3786 22.2317H85.0316L86.1835 12.8495C86.1998 12.7194 86.2067 12.5917 86.2091 12.4663H86.2323L86.2067 12.4407C86.2067 10.6223 84.7344 9.09888 82.8649 9.09888H81.2369H79.9062H76.2532H76.2509Z"
        fill="currentColor"
      />
      <path
        d="M54.6808 22.234H58.3338L59.5322 12.4686H62.2191L62.6325 9.1012H59.9455C57.9506 9.1012 56.26 10.5248 55.8931 12.457L55.8791 12.4686H55.8907C55.8745 12.5545 55.8582 12.6381 55.8466 12.7264L54.6785 22.234H54.6808Z"
        fill="currentColor"
      />
      <path
        d="M29.5648 18.6065L30.2708 12.8518C30.287 12.7217 30.294 12.594 30.2963 12.4686H30.3172L30.2963 12.4477C30.301 10.627 28.8239 9.1012 26.9545 9.1012H25.647H23.9935C21.908 9.1012 20.15 10.6572 19.8969 12.7264L19.1909 18.4835C18.9517 20.4342 20.4356 22.1551 22.3818 22.2271V22.234H22.5095H23.536H25.4682C27.5536 22.234 29.314 20.6781 29.5671 18.6065H29.5648ZM22.7928 18.8666L23.5778 12.4686H26.6642L25.8792 18.8666H22.7928Z"
        fill="currentColor"
      />
      <path
        d="M40.6633 12.4663L41.0767 9.09888H36.6689C34.5834 9.09888 32.8254 10.6548 32.5723 12.7241L31.85 18.6065C31.6131 20.5341 33.118 22.234 35.0595 22.234H39.4673L39.8807 18.8666H35.4729L36.2578 12.4686H40.6656L40.6633 12.4663Z"
        fill="currentColor"
      />
      <path
        d="M18.2318 10.4366L18.6452 7.06921H13.9262C11.8407 7.06921 10.0827 8.62518 9.82956 10.6944L8.85882 18.6066C8.62194 20.5341 10.1268 22.2341 12.0683 22.2341H16.785L17.1983 18.8667H12.765L13.7984 10.4389H18.2341L18.2318 10.4366Z"
        fill="currentColor"
      />
      <path
        d="M49.8249 0C46.4435 0 44.235 2.02044 43.8332 5.3019L41.7547 22.2341H45.4078L46.6061 12.4686H49.6925L48.4942 22.2341H52.1472L53.2991 12.8518C53.5429 10.8593 51.9893 9.10125 49.9804 9.10125H48.6103H47.0218L47.5257 5C47.6395 4.06874 48.4314 3.36972 49.3697 3.36972H91.0812L88.7821 22.1064C88.464 24.6935 86.2693 26.6349 83.6637 26.6349H4.14208L6.14858 10.2903C6.63395 6.34464 9.9967 3.36972 13.9725 3.36972H41.9126C42.3121 2.05295 42.9809 0.91268 43.8913 0.00232234H13.9725C6.46442 0 3.07148 4.9791 2.47464 9.83744L0.468136 26.1821L-0.000976562 30H83.659C88.9424 30 92.0102 26.1635 92.4514 22.5546L94.7505 3.81793L95.2197 0L49.8249 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_7866_6019">
        <rect width="95.2206" height="30" fill="white" transform="translate(-0.000976562)" />
      </clipPath>
    </defs>
  </svg>
);
