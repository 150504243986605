import { useCookieState } from '@/hooks/useCookieState';
import { routes } from '@/routes/routes';
import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from './CookieBanner.module.scss';

export const CookieBanner = () => {
  const { didUserRespond, setDidUserRespond, setDidUserConsent } = useCookieState();

  const handleUserAccept = (didUserAccept: boolean) => {
    setDidUserConsent(didUserAccept);
    setDidUserRespond(true);
  };

  return (
    !didUserRespond && (
      <div className={styles.banner}>
        <div className={styles.banner__textWrapper}>
          <p>We use cookies. View our&nbsp;</p>
          <Link to={routes.privacyPolicy} className={styles.banner__link}>
            privacy policy
          </Link>
          <p>&nbsp;for details.</p>
        </div>
        <div className={styles.banner__btnWrapper}>
          <button onClick={() => handleUserAccept(true)} className={styles.banner__btn___white}>
            <p className={styles.banner__btnText}>Accept cookies</p>
          </button>
          <button onClick={() => handleUserAccept(false)} className={styles.banner__btn}>
            <p className={clsx(styles.banner__btnText, styles.banner__btnText___white)}>Opt out</p>
          </button>
        </div>
      </div>
    )
  );
};
