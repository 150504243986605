import * as React from 'react';

export const NewWindowIcon = ({ className }: { className?: string }) => (
  <svg className={className} width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 19.2554H5V5.25537H12V3.25537H5C3.89 3.25537 3 4.15537 3 5.25537V19.2554C3 20.3554 3.89 21.2554 5 21.2554H19C20.1 21.2554 21 20.3554 21 19.2554V12.2554H19V19.2554ZM14 3.25537V5.25537H17.59L7.76 15.0854L9.17 16.4954L19 6.66537V10.2554H21V3.25537H14Z"
      fill="currentColor"
    />
  </svg>
);
