import React from 'react';
import clsx from 'clsx';
import * as styles from './Button.module.scss';
import { Link } from '@reach/router';

type ButtonProps = {
  children: string;
  size?: 'm' | 'l';
  className?: string;
  disabled?: boolean;
  type?: 'button' | 'submit' | 'reset';
  variant?: 'white' | 'filled';
  asLink?: boolean;
  to?: string;
  onClick?(): void;
};

export const Button = ({ children, size, disabled, className, variant, type, onClick, asLink, to }: ButtonProps) => {
  const Tag = asLink || to ? Link : 'button';

  return (
    <Tag
      className={clsx(
        styles.button,
        size === 'l' && styles.button__SizeL,
        variant === 'white' && styles.button___white,
        variant === 'filled' && styles.button__filled,
        disabled && styles.button__disabled,
        className,
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
      to={to as string}
    >
      {children}
    </Tag>
  );
};
