// extracted by mini-css-extract-plugin
export var header = "NavMenu-module--header--c07c8";
export var headerWrapper = "NavMenu-module--header-wrapper--844ae";
export var headerWrapperHidden = "NavMenu-module--header-wrapper--hidden--8c9f8";
export var header__btnMobileMenu = "NavMenu-module--header__btnMobileMenu--05299";
export var header__button = "NavMenu-module--header__button--cc91e";
export var header__logo = "NavMenu-module--header__logo--8856b";
export var nav = "NavMenu-module--nav--2cd17";
export var nav__item = "NavMenu-module--nav__item--82fc5";
export var nav__itemLink = "NavMenu-module--nav__itemLink--a4a75";
export var nav__itemTitle = "NavMenu-module--nav__itemTitle--fd73d";
export var nav__itemWithDimensions = "NavMenu-module--nav__itemWithDimensions--2d604";
export var nav__itemWithTitle = "NavMenu-module--nav__item--withTitle--10274";
export var nav__list = "NavMenu-module--nav__list--b7b03";
export var nav__submenu = "NavMenu-module--nav__submenu--50ba9";
export var nav__submenuItemsLists = "NavMenu-module--nav__submenuItemsLists--9bef6";
export var nav__submenuItemsLists__firstList = "NavMenu-module--nav__submenuItemsLists__firstList--e7946";
export var nav__submenuItemsLists__items = "NavMenu-module--nav__submenuItemsLists__items--3735b";
export var nav__submenuItemsLists__lists = "NavMenu-module--nav__submenuItemsLists__lists--b7c19";
export var nav__submenuItemsLists__secondList = "NavMenu-module--nav__submenuItemsLists__secondList--da5a9";
export var nav__submenuItemsLists__title = "NavMenu-module--nav__submenuItemsLists__title--c8251";
export var nav__submenuWrapper = "NavMenu-module--nav__submenuWrapper--50653";
export var nav__tag = "NavMenu-module--nav__tag--44ded";
export var nav__title = "NavMenu-module--nav__title--17304";
export var noPadding = "NavMenu-module--noPadding--8e8bd";
export var sectionWithSubmenu = "NavMenu-module--sectionWithSubmenu--bf904";