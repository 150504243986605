export const routes = {
  home: '/',
  work: '/work/',
  caseStudies: '/work/sectors/',
  caseStudy: ({ title }: { title: string }) =>
    `/work/sectors/${title.replace(/\s+/g, '-').replace(/\.+$/, '').replace(':', '').replace("'", '').toLowerCase()}`,
  solutions: '/solutions/',
  contact: '/contact',
  process: '/process',
  privacyPolicy: '/privacy-policy',
  ideas: '/ideas/',
  idea: ({ slug }: { slug: string }) => `/ideas/${slug}`,
  // @TODO: Add it
  careers: '/careers',
};
