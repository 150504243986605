import { HeaderQuery } from '@/generated/graphql';
import { Container } from '@/ui/components/container/Container';
import clsx from 'clsx';
import { Link } from 'gatsby';
import React from 'react';
import * as styles from './NavMenu.module.scss';

type Section = {
  name: string;
  headerLinks: {
    name: string;
    url: string;
    shouldOpenInNewTab: boolean;
  }[];
};

const SubmenuItemsLists = ({
  firstSection,
  secondSection,
  onClick,
}: {
  firstSection: Section;
  secondSection: Section;
  onClick: (e: React.MouseEvent) => void;
}) => (
  <div
    className={styles.nav__submenuItemsLists}
    role="region"
    aria-label={`${firstSection?.name} and ${secondSection?.name} sections`}
  >
    <div className={styles.nav__submenuItemsLists__lists}>
      <div className={styles.nav__submenuItemsLists__firstList}>
        <p className={styles.nav__submenuItemsLists__title}>{firstSection?.name}</p>
        <ul className={clsx(styles.nav__submenuItemsLists__items)}>
          {firstSection?.headerLinks?.map((link, i) => (
            <li key={i}>
              <Link to={link?.url!} target={link?.shouldOpenInNewTab ? '_blank' : undefined} onClick={onClick}>
                {link?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.nav__submenuItemsLists__secondList}>
        <p className={styles.nav__submenuItemsLists__title}>{secondSection?.name}</p>
        <ul className={clsx(styles.nav__submenuItemsLists__items)}>
          {secondSection?.headerLinks?.map((link, i) => (
            <li key={i}>
              <Link to={link?.url!} target={link?.shouldOpenInNewTab ? '_blank' : undefined} onClick={onClick}>
                {link?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export const NavMenu = ({ data }: { data: HeaderQuery }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.currentTarget.blur();
    setTimeout(() => {
      if (window.location.href.includes('solutions')) {
        window.location.reload();
      }
    }, 0);
  };

  const renderSections = (sectionLinks: any[], isSectionWithSubmenu: boolean) =>
    sectionLinks.map((item, index) => (
      <li
        key={index}
        className={clsx(
          styles.nav__item,
          !isSectionWithSubmenu && styles.nav__itemWithDimensions,
          !isSectionWithSubmenu && item?.name && styles.nav__itemWithTitle,
        )}
      >
        {item.firstSubsection ? (
          <SubmenuItemsLists
            firstSection={item.firstSubsection}
            secondSection={item.secondSubsection}
            onClick={handleClick}
          />
        ) : (
          <Link
            to={item?.url!}
            target={item?.shouldOpenInNewTab ? '_blank' : undefined}
            onClick={handleClick}
            className={clsx(styles.nav__itemLink, isSectionWithSubmenu && styles.noPadding)}
          >
            <p className={styles.nav__tag}>{item?.tag}</p>
            {item?.name && <p className={styles.nav__title}>{item.name}</p>}
          </Link>
        )}
      </li>
    ));

  return (
    <nav className={styles.nav} aria-label="Primary navigation">
      <ul className={styles.nav__list} role="menubar">
        {data.strapiHeader?.headerSections?.map((item) => {
          const itemsWithSubmenu = item?.sectionLinks?.filter((s) => s?.firstSubsection);
          const isSectionWithSubmenu = itemsWithSubmenu && itemsWithSubmenu?.length > 0;

          return (
            <li key={item?.mainLink?.url} role="none">
              <Link
                className={styles.nav__itemTitle}
                to={item!.mainLink?.url!}
                onClick={(e) => e.currentTarget.blur()}
                role="menuitem"
              >
                {item!.mainLink?.tag}
              </Link>
              <Container className={clsx(styles.nav__submenuWrapper)}>
                <ul
                  className={clsx(styles.nav__submenu, isSectionWithSubmenu && styles.sectionWithSubmenu)}
                  aria-label={`${item!.mainLink?.tag} submenu`}
                  role="menu"
                >
                  {item?.sectionLinks && renderSections(item.sectionLinks, !!isSectionWithSubmenu)}
                </ul>
              </Container>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};
