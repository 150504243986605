import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ContactModuleQuery } from '@/generated/graphql';
import contactBgImage from '../../../images/contact-bg.png';
import { FullWidthCTA } from '../fullWidthCTA/FullWidthCTA';

export const ContactSection = ({ withBackgroundImage }: { withBackgroundImage?: boolean }) => {
  const contactModuleData = useStaticQuery<ContactModuleQuery>(graphql`
    query ContactModule {
      strapiContactModule {
        text
        url
      }
    }
  `);

  return (
    <FullWidthCTA
      text={contactModuleData.strapiContactModule?.text || "Let's build something big."}
      url={contactModuleData.strapiContactModule?.url || '#'}
      backgroundImage={withBackgroundImage ? contactBgImage : undefined}
    />
  );
};
