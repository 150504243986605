import { HeaderQuery } from '@/generated/graphql';
import clsx from 'clsx';
import { Link } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Section } from '../navMenu/NavMenu';
import * as styles from './MobileNavMenu.module.scss';

const HamburgerIcon = ({ className }: { className?: string }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.5 18.5H21.5V16.5H3.5V18.5ZM3.5 13.5H21.5V11.5H3.5V13.5ZM3.5 6.5V8.5H21.5V6.5H3.5Z"
      fill="currentColor"
    />
  </svg>
);

const CloseIcon = ({ className }: { className?: string }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19.5 6.91L18.09 5.5L12.5 11.09L6.91 5.5L5.5 6.91L11.09 12.5L5.5 18.09L6.91 19.5L12.5 13.91L18.09 19.5L19.5 18.09L13.91 12.5L19.5 6.91Z"
      fill="currentColor"
    />
  </svg>
);

const ArrowForward = ({ className }: { className?: string }) => (
  <svg className={className} width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.5001 7.16663L18.1501 9.51663L27.4501 18.8333H7.16675V22.1666H27.4501L18.1501 31.4833L20.5001 33.8333L33.8334 20.5L20.5001 7.16663Z"
      fill="currentColor"
    />
  </svg>
);

const ArrowBackIcon = ({ className }: { className?: string }) => (
  <svg className={className} width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.833 11.5H8.66301L14.253 5.91L12.833 4.5L4.83301 12.5L12.833 20.5L14.243 19.09L8.66301 13.5H20.833V11.5Z"
      fill="white"
    />
  </svg>
);

export const MobileNavMenu = ({ data }: { data: HeaderQuery }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState<number | null>(null);

  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('no-scrollbar');
    } else {
      document.body.classList.remove('no-scrollbar');
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const renderSubmenuWithSectionLinks = ({
    firstSection,
    secondSection,
  }: {
    firstSection: Section;
    secondSection?: Section;
  }) => (
    <React.Fragment key={firstSection?.name}>
      <div className={styles.submenu}>
        <p className={styles.submenu__title}>{firstSection?.name}</p>
        <ul>
          {firstSection?.headerLinks?.map((item, i) => (
            <li key={i}>
              <Link style={{ color: 'white' }} to={item?.url!}>
                {item?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <div className={styles.submenu}>
        <p className={styles.submenu__title}>{secondSection?.name}</p>
        <ul className={styles.submenu__secondMenu}>
          {secondSection?.headerLinks?.map((item, i) => (
            <li key={i}>
              <Link style={{ color: 'white' }} to={item?.url!}>
                {item?.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </React.Fragment>
  );

  const selectedSubmenu = activeSubmenu !== null ? data.strapiHeader?.headerSections?.[activeSubmenu] : null;
  const submenu =
    activeSubmenu !== null ? (
      <div className={styles.menu__submenu}>
        <div className={styles.menu__mainItem}>
          <p>{selectedSubmenu?.mainLink?.tag}</p>
        </div>
        <ul>
          {selectedSubmenu?.sectionLinks?.map((item, i) =>
            item?.firstSubsection ? (
              renderSubmenuWithSectionLinks({
                firstSection: item.firstSubsection as Section,
                secondSection: item.secondSubsection as Section,
              })
            ) : (
              <li key={i} className={styles.menu__submenuItem}>
                <Link to={item?.url!}>
                  <p className={clsx(styles.menu__tag, item?.name && styles.menu__tagWithTitle)}>{item?.tag}</p>
                  {item?.name && <p className={styles.menu__title}>{item?.name}</p>}
                </Link>
              </li>
            ),
          )}
        </ul>
      </div>
    ) : null;

  const menu = (
    <div className={styles.menu}>
      <div className={styles.menu__mainMenuContent}>
        {data.strapiHeader?.headerSections?.map((item, i) => (
          <button key={i} className={styles.menu__mainItem} onClick={() => setActiveSubmenu(i)}>
            <p>{item?.mainLink?.tag}</p>
            <ArrowForward />
          </button>
        ))}
      </div>
      <div className={styles.menu__footer}>
        <Link
          to={data.strapiHeader?.firstLink?.url!}
          className={styles.menu__footerItem}
          target={data.strapiHeader?.firstLink?.shouldOpenInNewTab ? '_blank' : undefined}
        >
          {data.strapiHeader?.firstLink?.tag}
        </Link>
        <Link
          to={data.strapiHeader?.secondLink?.url!}
          className={styles.menu__footerItem}
          target={data.strapiHeader?.secondLink?.shouldOpenInNewTab ? '_blank' : undefined}
        >
          {data.strapiHeader?.secondLink?.tag}
        </Link>
        {data.strapiHeader?.contactNumbers?.map((item, i) => (
          <p key={i} className={styles.menu__phoneContact}>
            {item?.name} <a href={`tel:${item?.number}`}>{item?.number}</a>
          </p>
        ))}
        <div>
          <p className={styles.menu__socialLinksHeader}>Follow us on: </p>
          {data?.strapiHeader?.socialLinks?.map((item, i) => (
            <Link
              key={i}
              className={styles.menu__socialLink}
              to={item?.url!}
              target={item?.shouldOpenInNewTab ? '_blank' : undefined}
            >
              {item?.title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      <button
        aria-expanded={isMenuOpen}
        aria-controls="mobile-menu"
        aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
        onClick={toggleMenu}
        className={clsx(styles.menu__btn, styles.menu__hamburger)}
      >
        <HamburgerIcon />
      </button>
      {isMenuOpen && (
        <div
          id="mobile-menu"
          role="dialog"
          aria-modal="true"
          aria-label="Navigation menu"
          className={clsx(styles.menu__overlay, activeSubmenu !== null && styles.menu__overlayWithSubmenu)}
        >
          <div className={styles.menu__header}>
            <div className={styles.menu__headerLeft}>
              {activeSubmenu != null ? (
                <button onClick={() => setActiveSubmenu(null)}>
                  <ArrowBackIcon />
                </button>
              ) : null}
              Menu
            </div>
            <button onClick={toggleMenu} className={styles.menu__btn}>
              <CloseIcon />
            </button>
          </div>
          <div>{activeSubmenu !== null ? submenu : menu}</div>
        </div>
      )}
    </>
  );
};
