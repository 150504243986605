import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';

const setCookieState = (name: string, value: boolean) => Cookies.set(name, String(value), { expires: 365 });

export const useCookieState = () => {
  const [didUserConsent, setDidUserConsent] = useState(false);
  const [didUserRespond, setDidUserRespond] = useState(() => (Cookies.get('cookieConsent') ? true : false));

  useEffect(() => {
    if (didUserRespond) {
      setCookieState(
        'cookieConsent',
        Cookies.get('cookieConsent') ? Cookies.get('cookieConsent') === 'true' : didUserConsent,
      );
    }
  }, [didUserRespond, didUserConsent]);

  return { didUserRespond, setDidUserRespond, setDidUserConsent };
};
