import * as React from 'react';
import { Link } from 'gatsby';
import { Container } from '@/ui/components/container/Container';
import { ArrowUpRightIcon } from '@/ui/components/icons/ArrowUpRightIcon';
import * as styles from './FullWidthCTA.module.scss';
import clsx from 'clsx';

interface FullWidthCTAProps {
  text: string;
  url: string;
  backgroundImage?: string;
  icon?: React.ReactNode;
}

export const FullWidthCTA: React.FC<FullWidthCTAProps> = ({ text, url, backgroundImage, icon }) => (
  <Link
    to={url}
    className={clsx(styles.fullWidthCTA, backgroundImage && styles.fullWidthCTA___withBackground)}
    style={backgroundImage ? { backgroundImage: `url(${backgroundImage})` } : undefined}
  >
    <Container className={styles.fullWidthCTA__container}>
      <div className={styles.fullWidthCTA__inner}>
        <p className={styles.fullWidthCTA__text}>{text}</p>
        {icon ? icon : <ArrowUpRightIcon className={styles.fullWidthCTA__icon} />}
      </div>
    </Container>
  </Link>
);
